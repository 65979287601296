var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Gestão de Pedidos")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('PeriodSelectorComponent',{attrs:{"propPermiteNull":true},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"ma-1",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Aluno/Responsável","clearable":"","flat":"","hide-details":""},model:{value:(_vm.nomeAlunoResponsavel),callback:function ($$v) {_vm.nomeAlunoResponsavel=$$v},expression:"nomeAlunoResponsavel"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.masterPreInvoiceStatus(),"label":"Filtrar por Status","item-text":"name","clearable":"","hide-details":"","flat":""},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('v-col',[_c('v-btn',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1)],1),_c('v-col',[_c('v-data-table',{staticClass:"ma-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"fixed-header":"","height":"70vh","loading-text":"Carregando...","item-key":'id' + 'item_name',"footer-props":{
            itemsPerPageOptions: [-1],
          },"no-data-text":"Não há dados para essa filtragem"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(value))+" ")]}},{key:"item.responsavel_nome",fn:function(ref){
          var item = ref.item;
return [(item.full_name)?_c('span',[_vm._v(_vm._s(item.full_name))]):_vm._e()]}},{key:"item.responsavel_cpf",fn:function(ref){
          var item = ref.item;
return [(item.cpf)?_c('span',[_vm._v(_vm._s(_vm._f("formatCpfCnpj")(item.cpf)))]):_vm._e()]}},{key:"item.items",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.quantity)+" - "+_vm._s(item.item_name)+" "),(item.nome_aluno && !item.aluno_id)?_c('span',[_vm._v("  - "+_vm._s(item.nome_aluno[0])+" ")]):_vm._e(),(item.aluno_id)?_c('span',[_vm._v(" ")]):_vm._e(),(item.aluno_id)?_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.aluno_nome)),"propObjectId":item.aluno_id,"propToolName":"Aluno"}}):_vm._e()],1)]}},{key:"item.valor",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value))+" ")]}},{key:"item.status_id",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.masterPreInvoiceStatus(item.status_id).color,"small":""}},[_vm._v(" "+_vm._s(_vm.masterPreInvoiceStatus(item.status_id).name)+" ")])]}},{key:"item.contrato_livro_id",fn:function(ref){
          var value = ref.value;
return [(value)?_c('span',[_vm._v("Sim")]):_c('span',[_vm._v("Não")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.contrato_livro_id && item.status_id === 7)?_c('EntregarLivroButton',{attrs:{"propItem":{
                contrato_livro_id: item.contrato_livro_id,
                livro_data_entrega: item.livro_data_entrega,
                cancelado_data_hora: item.cancelado_data_hora,
              }},on:{"on-confirm":_vm.onConfirm}}):_vm._e(),(item.status_id === 1)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.livroDiretoFranchising(item)}}},[_vm._v("Enviar link pagamento ")]):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.dialogFranchisePreInvoiceVisualiza),callback:function ($$v) {_vm.dialogFranchisePreInvoiceVisualiza=$$v},expression:"dialogFranchisePreInvoiceVisualiza"}},[(_vm.preInvoiceId)?_c('FranchisePreInvoiceVisualizaComponent',{attrs:{"prop-pre-invoice-id":_vm.preInvoiceId},on:{"on-cancel":function($event){_vm.dialogFranchisePreInvoiceVisualiza = false}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }