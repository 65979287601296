import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { alunoStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import EntregarLivroButton from '@/components/EntregarLivroButton.vue';
import FranchisePreInvoiceVisualizaComponent from '@/components/FranchisePreInvoiceVisualizaComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.loading = false;
        this.dialogConfirmarDataEntregaLivro = false;
        this.dialogFranchisePreInvoiceVisualiza = false;
        this.contratoLivroId = 0;
        this.preInvoiceId = 0;
        this.items = [];
        this.nomeAlunoResponsavel = '';
        this.selectedStatus = null;
        this.selectPeriodDate = [];
        this.headers = [
            {
                text: 'Número',
                sortable: true,
                value: 'id',
                align: 'left',
            },
            {
                text: 'Criado em',
                sortable: true,
                value: 'created_at',
                align: 'left',
            },
            {
                text: 'Responsável pelo pedido - Nome',
                sortable: true,
                value: 'responsavel_nome',
                align: 'left',
            },
            {
                text: 'Responsável pelo pedido - CPF',
                sortable: true,
                value: 'responsavel_cpf',
                align: 'left',
            },
            {
                text: 'Livros - Alunos',
                sortable: true,
                value: 'items',
                align: 'left',
            },
            {
                text: 'Status do Pedido',
                sortable: true,
                value: 'status_id',
                align: 'left',
            },
            {
                text: 'Pedido atrelado ao aluno no Drive',
                sortable: true,
                value: 'contrato_livro_id',
                align: 'left',
            },
            {
                text: '',
                sortable: false,
                value: 'actions',
                align: 'left',
            },
        ];
    }
    entregarLivro(item) {
        this.contratoLivroId = item.contrato_livro_id;
        this.dialogConfirmarDataEntregaLivro = true;
    }
    async livroDiretoFranchising({ id }) {
        this.preInvoiceId = id;
        this.dialogFranchisePreInvoiceVisualiza = true;
    }
    onCancel() {
        this.preInvoiceId = 0;
        this.dialogConfirmarDataEntregaLivro = false;
    }
    async buscar() {
        await this.loadPage();
    }
    async onConfirm() {
        await this.loadPage();
    }
    async loadPage() {
        this.loading = true;
        const allItems = await alunoStore.getLivrosAlunosMASTER({
            nomeAlunoResponsavel: this.nomeAlunoResponsavel,
            dataInicial: this.selectPeriodDate[0],
            dataFinal: this.selectPeriodDate[1],
            status_id: this.selectedStatus,
        });
        this.items = allItems;
        this.loading = false;
    }
    async mounted() {
        this.selectedStatus = 1;
        await this.loadPage();
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            EntregarLivroButton,
            FranchisePreInvoiceVisualizaComponent,
            PeriodSelectorComponent,
        },
    })
], List);
export default List;
